import { useStaticQuery, graphql } from "gatsby"

export const useCategories = () => {
  const menu = useStaticQuery(graphql`
    query CategoriesQuery {
      allDatoCmsCategory(
        filter: { root: { eq: true }, locale: { eq: "en" } }
        sort: { fields: position, order: ASC }
      ) {
        nodes {
          name
          originalId
          slug
          root
          treeChildren {
            name
            originalId
            slug
            position
            treeChildren {
              name
              originalId
              slug
              position
            }
          }
        }
      }
    }
  `)
  return menu.allDatoCmsCategory.nodes
}
