import React from "react"
import styled from "styled-components"
import { Container, Row, Col } from "react-grid-system"
import DesignTokens from "./designTokens"
import logoWhite from "../images/triplok-logo-white.svg"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faLinkedin } from "@fortawesome/free-brands-svg-icons"
import { LinkButton } from "./button"
import { Body } from "./typography"

const Footer = () => (
  <Wrapper>
    <Container>
      <Row>
        <Col md={3}>
          <img src={logoWhite} />
        </Col>
        <Details>
          <Row>
            <Col md={6} xl={3}>
              Via Silvio Pellico, 19
              <br />
              24060 Montello (BG)
            </Col>
            <Col md={6} xl={3}>
              <ul>
                <li>
                  <a href="tel:+39035687130">T. +39 035 687130</a>
                </li>
                <li>
                  <a href="tel:+39035687132">T. +39 035 687132</a>
                </li>
                <li>F. +39 035 687121</li>
              </ul>
            </Col>
            <Col md={6} xl={3}>
              <a href="maito:vendite@triplok.it">vendite@triplok.it</a>
            </Col>
            <Col md={6} xl={3}>
              <a
                href="https://www.linkedin.com/company/cantiere-tri-plok-srl/"
                rel="noopener nofollow"
                target="blank"
              >
                <FontAwesomeIcon icon={faLinkedin} size="2x" />
              </a>
            </Col>
          </Row>
          <Row>
            <Col>
              Orari vino e giardino: Lun/Ven 8.00-12.00 13.30-18.30 | Sab
              8.30-12.00
            </Col>
          </Row>
        </Details>
        <Col md={3}>
          <Contacts>
            <Body>Trova subito la soluzione adatta alle tue esigenze.</Body>
            <LinkButton to="/contatti/">Contattaci</LinkButton>
          </Contacts>
        </Col>
      </Row>
      <Row>
        <ColWrapper>
          <Col md={12}> <a href="/cookie-policy">Cookie policy</a>  | <a href="/privacy-policy">Privacy policy</a> </Col>
          <Col md={12}>
            <strong>Informative</strong> | <a
              href="https://www.datocms-assets.com/38407/1690190510-informativa_clienti.pdf"
              target="_blank"
            >
              Clienti
            </a> | <a href="https://www.datocms-assets.com/38407/1690190514-informativa_fornitori.pdf" target="_blank">
              Fornitori
            </a> | <a href="https://www.datocms-assets.com/38407/1690190515-informativa_potenzialiclienti.pdf" target="_blank">
              Potenziali clienti
            </a> | <a href="https://www.datocms-assets.com/38407/1690190512-informativa_cv.pdf" target="_blank">
              CV
            </a>
          </Col>
        </ColWrapper>
      </Row>
    </Container>
  </Wrapper>
)

const ColWrapper = styled(Col)`
  
    margin-top: 2rem;
    padding-top: 2rem;
    padding-left: 0;
    padding-right: 0;
    & > * {
      padding-left: 0!important;
      padding-right: 0!important;
    }
  
`

const Wrapper = styled.footer`
  margin-top: 6rem;
  padding-top: 6rem;
  padding-bottom: 2rem;
  color: white;
  background-color: ${DesignTokens.colors.secondary[500]};
  font-size: 0.875rem;
  a {
    color: white;
  }
  ul {
    list-style: none;
    margin: 0;
    li {
      margin: 0;
    }
  }
  @media screen and (max-width: 767px) {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
`

const Details = styled(Col)`
  & > * {
    margin-bottom: 1.5rem;
    padding-bottom: 1.5rem;
    border-bottom: 1px solid white;
    &:last-child {
      margin-bottom: 0;
    }
  }
`

const Contacts = styled.div`
  & > * {
    margin-bottom: 1rem;
    &:last-child {
      margin-bottom: 0;
    }
  }
`

export default Footer
