/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React, { useState } from "react"
import Header from "./header"
import { Helmet } from "react-helmet"
import Footer from "./footer"

const Layout = ({ home, children }) => {
  return (
    <>
      <Helmet>
        <link
          href="https://fonts.googleapis.com/css2?family=Poppins:wght@400;600;900&display=swap"
          rel="stylesheet"
        />
      </Helmet>
      <Header home={home} />
      <main>{children}</main>
      <Footer />
    </>
  )
}

export { Layout }
