import styled from "styled-components"
import DesignTokens from "./designTokens"

const Display = styled.h1`
  font-size: ${DesignTokens.fontSizes[8]};
  font-weight: ${props => props.weight || 900};
  color: ${props => props.color || "inherit"};
  line-height: 1;
  text-transform: uppercase;
  text-align: ${props => props.align || "inherit"};
  @media screen and (max-width: 425px) {
    font-size: ${DesignTokens.fontSizes[8]};
  }
  sup {
    font-size: 50%;
    vertical-align: super;
  }
`
const DisplaySmall = styled.h2`
  font-size: ${DesignTokens.fontSizes[8]};
  font-weight: ${props => props.weight || 900};
  color: ${props => props.color || "inherit"};
  text-align: ${props => props.align || "inherit"};
`
const Heading = styled.h3`
  font-size: ${DesignTokens.fontSizes[6]};
  font-weight: ${props => props.weight || 900};
  color: ${props => props.color || DesignTokens.colors.primary[500]};
  text-align: ${props => props.align || "inherit"};
  line-height: 1.3;
  @media screen and (max-width: 767px) {
    font-size: ${DesignTokens.fontSizes[7]};
  }
`
const HeadingSmall = styled.h4`
  font-size: ${props => props.size || DesignTokens.fontSizes[5]};
  text-transform: uppercase;
  font-weight: ${props => props.weight || 900};
  color: ${props => props.color || DesignTokens.colors.secondary[500]};
  text-align: ${props => props.align || "inherit"};
  line-height: 1.1;
`
const Lead = styled.h5`
  font-size: ${props => props.size || DesignTokens.fontSizes[4]};
  text-transform: uppercase;
  font-weight: ${props => props.weight || 600};
  color: ${props => props.color || DesignTokens.colors.secondary[500]};
  text-align: ${props => props.align || "inherit"};
  line-height: 1.1;
`
const Body = styled.p`
  font-size: ${props => props.size || DesignTokens.fontSizes[4]};
  font-weight: ${props => props.weight || 400};
  color: ${props => props.color || "inherit"};
  text-align: ${props => props.align || "inherit"};
  line-height: 1.4;
  margin: 0;
`
const BodySmall = styled.p`
  font-size: ${DesignTokens.fontSizes[3]};
  font-weight: ${props => props.weight || 400};
  color: ${props => props.color || "inherit"};
  text-align: ${props => props.align || "inherit"};
  margin: 0;
`
const Caption = styled.p`
  font-size: ${DesignTokens.fontSizes[3]};
  color: ${props => props.color || DesignTokens.colors.primary[500]};
  font-weight: ${props => props.weight || 400};
  text-align: ${props => props.align || "inherit"};
`
const CaptionSmall = styled.p`
  font-size: ${DesignTokens.fontSizes[2]};
  font-weight: ${props => props.weight || 400};
  color: ${props => props.color || "inherit"};
  text-align: ${props => props.align || "inherit"};
`
const Blockquote = styled.blockquote`
  font-size: ${DesignTokens.fontSizes[5]};
  font-weight: ${props => props.weight || 400};
  color: ${props => props.color || DesignTokens.colors.secondary[500]};
  text-transform: uppercase;
  font-weight: bold;
  text-align: ${props => props.align || "inherit"};
  margin-left: 0;
  margin-top: 2rem;
  position: relative;
`

export {
  Display,
  DisplaySmall,
  Heading,
  HeadingSmall,
  Lead,
  Body,
  BodySmall,
  Caption,
  CaptionSmall,
  Blockquote,
}
