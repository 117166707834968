import { Link } from "gatsby"
import styled from "styled-components"
import React, { useContext, useState } from "react"
import { Container, Row, Col } from "react-grid-system"
import logo from "../images/triplok-logo.svg"
import logoWhite from "../images/triplok-logo-white.svg"
import DesignTokens from "./designTokens"
import DropdownMenu from "./dropdownMenu"
import MegaMenu from "./megaMenu"
import ProductMenu from "./productMenu"
import { Menu, X } from "react-feather"
import { motion, AnimatePresence } from "framer-motion"
import { WishlistContext } from "../context/wishlistContext"
import { useStaticQuery } from "gatsby"

const Header = ({ home, siteTitle }) => {
  const [showMenu, setShowMenu] = useState(false)
  const { wishlist, setWishlist } = useContext(WishlistContext)

  const dataBanner = useStaticQuery(graphql`
    query NavQuery {
      allDatoCmsBanner {
        nodes {
          id
          text
          show
          locale
        }
      }
    }
  `)

  const banner = dataBanner.allDatoCmsBanner.nodes.filter(obj => {
    return obj.locale === "it"
  })

  return (
    <>
      {banner && banner[0] && banner[0].show && (
        <p
          className="BannerWrap"
          style={{
            color: "white",
          }}
          dangerouslySetInnerHTML={{ __html: banner[0].text }}
        ></p>
      )}

      <Wrapper home={home}>
        <DesktopMenu>
          <Link to="/">
            <img src={home ? logoWhite : logo} />
          </Link>
          <Links home={home}>
            <MegaMenu anchor="Prodotti">
              <ProductMenu />
            </MegaMenu>
            <DropdownMenu anchor="Klimatrave">
              <Link to="/klimatrave/" activeClassName="active">
                Sistema isolante portante
              </Link>
              <Link to="/klimatrave-posa-in-opera/" activeClassName="active">
                Posa in opera
              </Link>
            </DropdownMenu>
            <li>
              <Link to="/enologia/">Enologia</Link>
            </li>
            <DropdownMenu anchor="Azienda">
              <Link to="/chi-siamo/" activeClassName="active">
                Chi siamo
              </Link>
              <Link to="/competenze/" activeClassName="active">
                Competenze
              </Link>
              <Link to="/storia/" activeClassName="active">
                Storia
              </Link>
            </DropdownMenu>
            <li>
              <Link to="/news/">News</Link>
            </li>
            <li>
              <Link to="/contatti/">Contatti</Link>
            </li>
            <li>
              <Link to="/wishlist/">Wishlist ({wishlist.length})</Link>
            </li>
          </Links>
        </DesktopMenu>
        <Container>
          <MobileMenu>
            <Row>
              <Col>
                <MobileLinks home={home}>
                  <li>
                    <Link to="/">
                      <img src={home ? logoWhite : logo} />
                    </Link>
                  </li>
                  <div>
                    <Button
                      role="button"
                      tabIndex={0}
                      onClick={() => setShowMenu(!showMenu)}
                      onKeyDown={() => setShowMenu(!showMenu)}
                    >
                      <Menu
                        color={
                          home ? "white" : DesignTokens.colors.secondary[500]
                        }
                      />
                    </Button>
                  </div>
                </MobileLinks>
              </Col>
            </Row>
          </MobileMenu>
        </Container>
        <AnimatePresence>
          {showMenu && (
            <StyledOverlay
              initial={{ opacity: 0, scale: 1.2 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{ duration: 0.15 }}
              exit={{ opacity: 0 }}
            >
              <header>
                <X size={32} onClick={() => setShowMenu(false)} />
              </header>
              <ul>
                <li>
                  Prodotti
                  <ul>
                    <li>
                      <Link to="/arredo-giardino/">Arredo Giardino</Link>
                    </li>
                    <li>
                      <Link to="/arredo-urbano/">Arredo Urbano</Link>
                    </li>
                    <li>
                      <Link to="/edilizia/">Edilizia</Link>
                    </li>
                    <li>
                      <Link to="/wishlist/">Wishlist ({wishlist.length})</Link>
                    </li>
                  </ul>
                </li>
                <li>
                  Klimatrave
                  <ul>
                    <li>
                      <Link to="/klimatrave/" activeClassName="active">
                        Sistema isolante portante
                      </Link>
                    </li>
                    <li>
                      {" "}
                      <Link
                        to="/klimatrave-posa-in-opera/"
                        activeClassName="active"
                      >
                        Posa in opera
                      </Link>
                    </li>
                  </ul>
                </li>
                <li>
                  Azienda
                  <ul>
                    <li>
                      <Link to="/chi-siamo/" activeClassName="active">
                        Chi siamo
                      </Link>
                    </li>
                    <li>
                      <Link to="/competenze/" activeClassName="active">
                        Competenze
                      </Link>
                    </li>
                    <li>
                      <Link to="/storia/" activeClassName="active">
                        Storia
                      </Link>
                    </li>
                  </ul>
                </li>
                <li>
                  <Link to="/enologia/">Enologia</Link>
                </li>
                <li>
                  <Link to="/news/">News</Link>
                </li>
                <li>
                  <Link to="/contatti/">Contatti</Link>
                </li>
              </ul>
            </StyledOverlay>
          )}
        </AnimatePresence>
      </Wrapper>
    </>
  )
}

const Wrapper = styled.header`
  z-index: 1;
  position: relative;
  ${props =>
    props.home
      ? `position: absolute;
    z-index: 1;
    width: 100%;`
      : null};
`

const DesktopMenu = styled.div`
  display: none;
  @media screen and (min-width: 1025px) {
    width: 100%;
    padding: 0 5%;
    display: flex;
    justify-content: space-between;
  }
  img {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }
`

const MobileMenu = styled.div`
  display: block;
  @media screen and (min-width: 1025px) {
    display: none;
  }
`

const Button = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 40px;
`

const Links = styled.ul`
  list-style: none;
  margin: 0;
  display: flex;
  flex-direction: row;
  & > li {
    display: flex;
    align-items: center;
    padding: 1.5rem 1rem;
    @media screen and (max-width: 1025px) {
      padding-left: 0;
    }
    & > a {
      text-decoration: none;
      text-transform: uppercase;
      color: ${props =>
        props.home ? "white" : DesignTokens.colors.secondary[500]};
      font-weight: 600;
      &:hover {
        color: ${props =>
          props.home ? "white" : DesignTokens.colors.primary[500]};
      }
    }
  }
  & > * {
    margin-right: 1rem;
    margin-bottom: 0;
    &:last-child {
      margin-right: 0;
    }
  }
`

const MobileLinks = styled.ul`
  list-style: none;
  margin: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  & > li {
    display: flex;
    align-items: center;
    padding: 1.5rem 1rem;
    @media screen and (max-width: 1025px) {
      padding-left: 0;
    }
    & > a {
      text-decoration: none;
      text-transform: uppercase;
      color: ${props =>
        props.home ? "white" : DesignTokens.colors.secondary[500]};
      font-weight: 600;
      &:hover {
        color: ${props =>
          props.home ? "white" : DesignTokens.colors.primary[500]};
      }
    }
  }
  & > * {
    margin-right: 2rem;
    margin-bottom: 0;
    &:first-child {
      margin-right: 4rem;
    }
    &:last-child {
      margin-right: 0;
    }
  }
`

const StyledOverlay = styled(motion.div)`
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  color: ${DesignTokens.colors.white};
  background: ${DesignTokens.colors.primary[500]};
  z-index: 10;
  overflow: scroll;
  padding-bottom: 2rem;
  header {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 70px;
    text-align: right;
    padding: 0 1.2rem;
  }
  ul {
    padding: 0;
    margin: 0;
    list-style-type: none;
    display: flex;
    flex-direction: column;
    & > li {
      font-size: ${DesignTokens.fontSizes[3]};
      padding: ${DesignTokens.spacing[3]};
      text-align: center;
      margin: 0;
      text-transform: uppercase;
      & > a {
        font-size: ${DesignTokens.fontSizes[5]};
        font-family: "Yantramanav";
        color: ${DesignTokens.colors.white};
        text-decoration: none;
        text-transform: capitalize;
        border-bottom: 1px solid #ffffff40;
      }
      ul > li {
        padding-bottom: 0;
      }
    }
  }
`

export default Header
