import React, { useState } from "react"
import { Link } from "gatsby"
import { useCategories } from "../hooks/useCategories"
import styled from "styled-components"
import DesignTokens from "./designTokens"
import { Body, HeadingSmall, Lead } from "./typography"
import { Col, Row } from "react-grid-system"

const ProductMenu = () => {
  const categories = useCategories()
  categories.map(category => {
    category.treeChildren.sort((a, b) => a.position - b.position)
    category.treeChildren.map(category => {
      if (category.treeChildren.length > 0) {
        category.treeChildren.sort((a, b) => a.position - b.position)
      }
    })
  })
  return (
    <Wrapper>
      <Row justify="center">
        {categories.map(category => (
          <Col md={4} lg={3} key={category.originalId}>
            <HeadingSmall>{category.name}</HeadingSmall>
            <ul>
              {category.treeChildren.slice(0, 5).map(subCategory => (
                <li key={subCategory.originalId}>
                  <Link
                    to={`/${category.slug}/${subCategory.slug}/`}
                    activeClassName="selected"
                    partiallyActive={true}
                  >
                    {subCategory.name}
                  </Link>
                </li>
              ))}
            </ul>
            <SeeMore to={`/${category.slug}/`}>Vedi tutti i prodotti ›</SeeMore>
            <GalleryLink to={`/realizzazioni/${category.slug}/`}>
              Gallery realizzazioni ›
            </GalleryLink>
          </Col>
        ))}
      </Row>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  padding-top: 4rem;
  padding-bottom: 4rem;
  color: black;
  a {
    color: ${DesignTokens.colors.secondary[500]};
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
  h4 {
    margin-bottom: 1.5rem;
    color: ${DesignTokens.colors.primary[500]};
  }
  ul {
    list-style: none;
    margin: 0;
    margin-bottom: 1rem;
    & > li {
      padding-bottom: 0.25rem;
    }
  }
`

const GalleryLink = styled(Link)`
  display: block;
  padding-top: 1rem;
  margin-top: 1rem;
  border-top: 1px solid #ddd;
  font-size: 1rem;
  font-weight: 400;
  text-decoration: none;
  text-transform: uppercase;
  color: ${DesignTokens.colors.secondary[500]};
  &:hover {
    text-decoration: underline;
  }
`

const SeeMore = styled(Link)`
  display: block;
  font-weight: 700;
  text-decoration: none;
  text-transform: uppercase;
  color: ${DesignTokens.colors.primary[500]};
  &:hover {
    text-decoration: underline;
  }
`

export default ProductMenu
