import React, { useState } from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import DesignTokens from "./designTokens"

const DropdownMenu = ({ anchor, children, path }) => {
  const [showMenu, setShowMenu] = useState(false)
  return (
    <Item
      onMouseEnter={() => setShowMenu(!showMenu)}
      onMouseLeave={() => setShowMenu(!showMenu)}
    >
      <a>{anchor}</a>
      {showMenu && <Dropdown>{children}</Dropdown>}
    </Item>
  )
}

const Item = styled.li`
  cursor: default;
  & > a {
    &::after {
      content: "▾";
      position: relative;
      margin-left: 1rem;
    }
  }
`

const Dropdown = styled.div`
  position: absolute;
  margin-left: 0;
  top: 6rem;
  text-align: left;
  background: ${DesignTokens.colors.white};
  display: flex;
  flex-direction: column;
  border: 1px solid ${DesignTokens.colors.primary[500]};
  & > a {
    padding: 0.5rem 1rem;
    color: ${DesignTokens.colors.primary[500]};
    text-decoration: none;
    &:hover {
      color: white;
      background-color: ${DesignTokens.colors.primary[500]};
    }
  }
`

export default DropdownMenu
